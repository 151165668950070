import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import Contact from '@/models/CRM/Contact';
import COCDataResponse from '@/models/Organizations/COCDataResponse';
import COCOrg from '@/models/Organizations/COCOrganization';
import Organization from '@/models/Purchases/Organization';
import PurchaseOrganizationSettings from '@/models/Organizations/PurchaseOrganizationSettings';
import Attachment from '@/models/Attachment';

export default class OrganizationsServicePurchases extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}organizations`;

    public async getOrganizations(): Promise<Range<Organization>> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_ORGANIZATIONS_FAILED`, err);
            return new Range<Organization>();
        }

        return new Range<Organization>(response.data);
    }

    public async getOrganization(organizationId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:organizationId`, [['organizationId', organizationId]]));

        if (err) {
            this.clearAndShowError(`LOAD_ORGANIZATION_FAILED`, err);
            return new Organization();
        }

        return new Organization(response.data);
    }

    public async getContacts(organizationId: number): Promise<Range<Contact>> {
        const [err, response] = await to(this.get(`${this.endpoint}/:organizationId/contacts`, [['organizationId', organizationId]]));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACTS_FAILED`, err);
            return new Range<Contact>();
        }

        return new Range<Contact>(response.data);
    }

    public async createContact(organizationId: number, contact: Contact): Promise<Contact> {
        const [err, response] = await to(this.post(`${this.endpoint}/:organizationId/contacts`, contact, [['organizationId', organizationId]]));
        if (err) {
            this.clearAndShowError(`CREATE_CONTACT_FAILED`, err);
            return new Contact();
        }

        return new Contact(response.data);
    }

    public updateContact(contact: Contact, organizationId: number) {
        return this.put(`${this.endpoint}/:organizationId/contacts/${contact.contactId}`, contact, [['organizationId', organizationId]]);
    }

    public async archiveContact(organizationId: number, contact: Contact) {
        return this.delete(`${this.endpoint}/:organizationId/contacts/:contactId`, [
            ['organizationId', organizationId],
            ['contactId', contact.contactId],
        ]);
    }

    public createOrganization(organization: Organization) {
        return this.post(this.endpoint, organization);
    }

    public updateOrganization(organization: Organization) {
        return this.put(`${this.endpoint}/${organization.organizationId}`, organization);
    }

    public copyDeal(organization: Organization) {
        return this.put(`${this.endpoint}/${organization.organizationId}`, organization);
    }

    public async suggestCOCData(search: string) {
        const [err, response] = await to(
            this.get(`https://api.overheid.io/v3/suggest/openkvk/${search}?size=25&fields[]=handelsnaam,dossiernummer`, null, {
                headers: {
                    'ovio-api-key': '7b381ff5b39e220643595256f516d8f06fbb54811d1ccfa33a709a041da8ad68',
                },
            }),
        );
        if (err) {
            return this.clearAndShowError('COC_API_ERROR', err);
        }

        if (response.data.dossiernummer) {
            return response.data.dossiernummer.map((x) => new COCDataResponse(x));
        }

        return response.data.handelsnaam.map((x) => new COCDataResponse(x));
    }

    public async getCOCdata(link: string) {
        const [err, response] = await to(
            this.get(`https://api.overheid.io${link}`, null, {
                headers: {
                    'ovio-api-key': '7b381ff5b39e220643595256f516d8f06fbb54811d1ccfa33a709a041da8ad68',
                },
            }),
        );
        if (err) {
            return this.clearAndShowError('COC_API_ERROR', err);
        }

        return new COCOrg(response.data);
    }

    public async getSettings(organizationId: number): Promise<PurchaseOrganizationSettings> {
        const response = await this.get(`${this.endpoint}/${organizationId}/settings`);
        return new PurchaseOrganizationSettings(response.data);
    }

    public async saveSettings(organizationId: number, payload: PurchaseOrganizationSettings) {
        return await this.put(`${this.endpoint}/${organizationId}/settings`, {
            aliases: payload.aliases,
            defaultPaymentMethod: payload.defaultPaymentMethod && payload.defaultPaymentMethod.id ? payload.defaultPaymentMethod.id : null,
        });
    }

    public async getAttachments(organizationId: number): Promise<Attachment[]> {
        const [err, response] = await to(this.get(`${this.endpoint}/${organizationId}/attachments`));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACTS_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new Attachment(x));
    }

    public async deleteAttachment(organizationId: number, fileId: string) {
        return this.delete(`${this.endpoint}/${organizationId}/attachments/${fileId}`);
    }

    public getOrganizationProducts(organizationId: number) {
        return this.get(`${this.endpoint}/${organizationId}/products`);
    }

    public async connectProductToOrganization(productId: number, organizationId: number) {
        return this.post(`${this.endpoint}/${organizationId}/products`, { productId });
    }

    public deleteProduct(organizationProductId: number, organizationId: number) {
        return this.delete(`${this.endpoint}/${organizationId}/products/${organizationProductId}`);
    }

    public mergeOrganizations(fromId: number, toId: number): Promise<Organization> {
        const self = this;
        return new Promise<Organization>((resolve, reject) => {
            self.post<Organization>(`${this.endpoint}/${fromId}/merge`, { destinationOrganizationId: toId })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((reason: any) => {
                    reject(reason);
                });
        });
    }
}
